import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import { Colors } from '@/classes/colors/Colors'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import VoiceStatsService from '@/services/voice-stats.service'
import DateRange from '@/components/Graphs/DateRange/DateRange.vue'
import getSymbolFromCurrency from 'currency-symbol-map'
import VueApexCharts from 'vue-apexcharts'
import { ExportForm } from '@/classes/ExportForm'
import getEnv from '@/util/env'
import ImpactCostGeneral from '@/components/Graphs/ImpactCostGeneral/ImpactCostGeneral.vue'
import { ImpactCost } from '@/classes/stats/ImpactCost.js'
import ConfirmCustomFieldsModal from '@/components/Stats/ConfirmCustomFieldsModal/ConfirmCustomFieldsModal.vue'
import ShareButton from '@/components/Stats/ShareButton/ShareButton.vue'
import ShareModal from '@/components/Stats/ShareModal/ShareModal.vue'
import EventBus from '@/util/EventBus'
import moment from 'moment'
import StatsMixin from '../../../../Stats/Mixins/StatsMixin'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import { VoiceGraphicData } from '@/classes/stats/VoiceGraphicData'
import { VoiceCalculatedData } from '@/classes/stats/Calculations/Voice/VoiceCalculatedData'
import RadialBarWithLegend from '@/components/Stats/RadialBar/RadialBarWithLegend.vue'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'
import StackedMultiple from '@/components/Stats/StackedColumn/StackedMultiple.vue'
import SimpleBar from '@/components/Stats/SimpleBar/SimpleBar.vue'
import StackedColumn from '@/components/Stats/StackedColumn/StackedColumn.vue'

export default {
    props: {
      campaignId: {
        type: Number,
        required: true,
      },
    },
    name: 'CampaignVoiceInteractiveStats',
    components: {
      StatsTitle,
      DateRange,
      apexchart: VueApexCharts,
      ImpactCostGeneral,
      ConfirmCustomFieldsModal,
      ShareButton,
      ShareModal,
      StatCard,
      RadialBarWithLegend,
      StackedMultiple,
      SimpleBar,
      StackedColumn,
      RadialBar,
    },
    mixins: [StatsMixin],
    data: function () {
      return {
        exportForm: new ExportForm(),
        globalControl: new GlobalControl(),
        loading: true,
        options: {},
        content: [],
        searchTerm: '',
        itemsPerPage: 10,
        pageCount: 0,
        page: 1,
        totalCampaigns: 0,
        chartDataInfo: [],
        attentionCallsPercentage: '0',
        unsubscribedPercentage: '0',
        unsubscribedTotal: 0,
        data: [],
        additional: undefined,
        globalTotalSms: 0,
        currencySymbol: '',
        ready: true,
        series: [],
        chartOptions: {
          legend: {
            show: false,
          },
          labels: [this.$t('Atendidas').toString(), this.$t('No Atendidas').toString()],
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
              },
            },
          }],
        },
        debouncedInput: '',
        timeout: null,
        impactCost: new ImpactCost(),
        campaign: {},
        selectedHeaders: [],
        sendingDetails: {
          initDate: '',
          endDate: moment().format('YYYY-MM-DD'),
          customFields: false,
          events: [],
          columns: [],
          sendingId: null,
          campaignType: null,
        },
        arrStatuses: Colors.statusesColors,
        campaginDataSource: {},
        readyStats: false,
        pressedKeysData: {},
        loadedPressedKeys: false,
        tags: '',
        voiceCalculatedData: new VoiceCalculatedData(),
        cards: [],
        deliveredRatioGraphic: null,
        pulseRatioBarChart: null,
        redGraphicData: null,
        deliveredGraphicData: null,
      }
    },
    computed: {
      headers () {
        return [
          { text: this.$t('ID Envío'), align: 'start', value: 'sending_id' },
          { text: this.$t('Fecha'), align: 'start', value: 'sending_date' },
          { text: this.$t('Llamadas'), align: 'start', value: 'fixed_sent_total' },
          { text: this.$t('Atendidas'), align: 'start', value: 'delivered_total' },
          { text: this.$t('No Atendidas'), align: 'start', value: 'fixed_undelivered_total' },
          { text: this.$t('Caducadas'), align: 'start', value: 'expired_total' },
          { text: this.$t('Bajas'), align: 'start', value: 'unsubscribed_total' },
        ]
      },
      pressedKeysHeaders () {
        return [
          { text: this.$t('Tecla'), align: 'start', value: 'key' },
          { text: this.$t('Acción'), align: 'start', value: 'action' },
          { text: this.$t('Locución'), align: 'start', value: 'locution' },
          { text: this.$t('Pulsaciones'), align: 'start', value: 'pressed_number' },
        ]
      },
      showHeaders () {
        return this.headers.filter(s => this.selectedHeaders.includes(s))
      },
      params (nv) {
        return {
          ...this.options,
          query: this.searchTerm,
        }
      },
      searchTermHelper: {
        get: function () {
          return this.debouncedInput
        },
        set: function (val) {
          this.debouncedInput = val
          if (this.timeout !== null) {
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(() => {
            this.searchTerm = val
          }, 700)
        },
      },
      locale () {
        return this.$i18n.locale
      },
    },
    methods: {
      setGraphicsData () {
        const voiceGraphicData = new VoiceGraphicData(this.voiceCalculatedData, this)
        this.cards = voiceGraphicData.getCardsData()
        this.deliveredRatioGraphic = voiceGraphicData.getDeliveredRatioGraphicGraphicData()
        this.pulseRatioBarChart = voiceGraphicData.getPulseRatioBarChartGraphicData(this.pressedKeysData)
        this.redGraphicData = voiceGraphicData.getRedGraphicData()
        this.deliveredGraphicData = voiceGraphicData.getDeliveredGraphicData()
      },
      selectedColumn (column) {
        let exist = false
        for (let i = 0; i < this.selectedHeaders.length; i = i + 1) {
          if (!exist) {
            exist = this.selectedHeaders[i].value === column
          }
        }
        return exist
      },
      showConfirmCustomFields (actionMode) {
        EventBus.$emit('showConfirmCustomFields', actionMode)
      },
      getColumns () {
        const columns = []
        this.headers.forEach(function (column) {
          columns.push(column.value)
        })
        return columns
      },
      setExportData () {
        this.exportForm.setFromParamsAndColums(this.getParams(), this.getColumns(), this.$route.params.shareHash)
      },
      exportAction (urlSuffix) {
        this.setExportData()
        const url = getEnv('backend_endpoint') + 'api/share/stats/voice/campaign-export-' + urlSuffix + '?' + this.exportForm.getUrlParams()
        window.location.href = url
      },

      exportCsv () {
        this.exportAction('csv')
      },

      exportPdf () {
        this.exportAction('pdf')
      },

      sumReduceByKey (items, key) {
        return items.reduce(
          function (a, b) {
            return a + parseInt(b[key])
         }, 0)
      },

      sumReduceDecimalByKey (items, key) {
        return items.reduce(
          function (a, b) {
            return a + parseFloat(b[key])
         }, 0).toFixed(3)
      },

      calculateTotalCalls (data) {
        let fixedSentTotal = parseInt(data.delivered_total) + parseInt(data.rejected_total) + parseInt(data.expired_total) + parseInt(data.undelivered_total)
        if (fixedSentTotal < 0) {
          fixedSentTotal = 0
        }
        return fixedSentTotal
      },

      calculateUndeliveredTotal (data) {
        let fixedUndeliveredTotal = parseInt(data.rejected_total) + parseInt(data.undelivered_total)
        if (fixedUndeliveredTotal < 0) {
          fixedUndeliveredTotal = 0
        }
        return fixedUndeliveredTotal
      },

      setTableData (data) {
        const campaignIds = []
        data.forEach(data => {
          data.fixed_sent_total = this.calculateTotalCalls(data)
          data.fixed_undelivered_total = this.calculateUndeliveredTotal(data)
          campaignIds.push(data.campaign_id)
        })
        this.content = data

        const totalSms = this.additional[1]
        if (totalSms) {
          this.globalTotalSms = totalSms.cost != null ? parseFloat(totalSms.cost) : parseFloat('0.000')
        }
      },

      resetReady () {
        this.ready = false
        setTimeout(() => {
          this.ready = true
        })
      },

      // eslint-disable-next-line max-lines-per-function
      setPieChart (totals) {
        totals.fixed_sent_total = this.calculateTotalCalls(totals)
        totals.fixed_undelivered_total = this.calculateUndeliveredTotal(totals)
        totals.delivered_total = parseInt(totals.delivered_total)
        this.series = [totals.delivered_total, totals.fixed_undelivered_total]
        this.chartDataInfo = [
          {
            label: this.$t('Llamadas').toString(),
            value: totals.fixed_sent_total,
          },
          {
            label: this.$t('Atendidas').toString(),
            value: totals.delivered_total,
          },
          {
            label: this.$t('No atendidas').toString(),
            value: totals.fixed_undelivered_total,
          },
        ]

        this.attentionCallsPercentage = totals.fixed_sent_total === 0 ? totals.fixed_sent_total : ((totals.delivered_total / totals.fixed_sent_total) * 100).toFixed(2)
        const unsubscribedPercentage = (totals.unsubscribed_total / totals.delivered_total) * 100
        this.unsubscribedPercentage = unsubscribedPercentage > 0 ? unsubscribedPercentage.toFixed(2) : '0'
        this.unsubscribedTotal = parseInt(totals.unsubscribed_total)
        this.resetReady()
      },

      getParams () {
        const params = {
          page: this.options.page,
          perPage: this.options.itemsPerPage,
          searchTerm: this.searchTerm,
          campaignId: this.campaignId,
        }

        for (let i = 0; i < this.options.sortBy.length; i++) {
          params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
          params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
            ? 'asc'
            : 'desc'
        }
        return params
      },
      getDataFromApi () {
        const params = this.getParams()
        this.content = []
        this.loading = true
        VoiceStatsService.getShareCampaignDatatable(params, this.$route.params.shareHash)
          .then(
            (response) => {
              this.readyStats = false
              this.additional = JSON.parse(JSON.stringify(response.additional))
              this.campaginDataSource = response
              this.data = JSON.parse(JSON.stringify(response.data))
              this.setTableData(response.data)
              this.itemsPerPage = parseInt(response.per_page)
              this.page = response.current_page
              this.totalCampaigns = response.total
              this.setPieChart(this.additional[0])

              if (this.data.length) {
                this.sendingDetails.initDate = moment(this.data[0].sending_date).format('YYYY-MM-DD')
                this.sendingDetails.sendingId = this.data[0].sending_id
                this.sendingDetails.campaignType = this.data[0].campaign_type_name
              }

              setTimeout(() => {
                this.voiceCalculatedData.calculate(this.campaginDataSource)
                this.setGraphicsData()
                this.readyStats = true
              })
            },
            () => { },
          )
          .finally(() => {
            this.loading = false
          })
      },
      statusColor (campaign) {
        return this.arrStatuses[campaign.status_name]?.color
      },
    },
    watch: {
      params: {
        handler () {
          this.getDataFromApi()
        },
        deep: true,
      },
      locale () {
        this.setGraphicsData()
      },
    },
    created () {
      this.selectedHeaders = this.headers

      setTimeout(() => {
          this.getDataFromApi()
      })

      VoiceStatsService.getShareCampaignData(this.campaignId, this.$route.params.shareHash)
      .then(
        (response) => {
          this.campaign = response.campaign
          this.currencySymbol = getSymbolFromCurrency(response.currencyCode)
          response.channel.menu = JSON.parse(response.channel.menu)
          this.channel = response.channel
          this.pressedKeysData = response.pressedKeysData
          this.loadedPressedKeys = true
          this.tags = response.tags
        },
        (err) => {
          EventBus.$emit('showAlert', 'warning', this.$t(err.response.data.message))
          EventBus.$emit('showLoading', false)
          this.$router.push({ name: 'voiceStats' })
        },
      )
    },
    mounted () {
      this.globalControl.globals = false
    },
}
